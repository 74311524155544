<template>
  <b-card title="Araç Bilgisi">
    <b-row>
      <b-col cols="12">
        <arac-kodu />
      </b-col>
      <b-col cols="12">
        <registration-date />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <brands />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <models />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <car-title />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <model-year />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <fuels />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <gears />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <chasis />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <engine />
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <licence-plate />
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <license-number />
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <arvento-no />
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <utts-no />
      </b-col>
      <b-col
        cols="12"
      >
        <colors />
      </b-col>
      <b-col cols="12">
        <checkboxes />
      </b-col>
      <b-col
        cols="12"
      >
        <stock-status :is-new="isNew" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import AracKodu from '@/views/Rental/Stocks/elements/AracKodu.vue'
import RegistrationDate from '@/views/Rental/Stocks/elements/RegistrationDate.vue'
import Brands from '@/views/Rental/Stocks/elements/Brands.vue'
import Models from '@/views/Rental/Stocks/elements/Models.vue'
import CarTitle from '@/views/Rental/Stocks/elements/Title.vue'
import Chasis from '@/views/Rental/Stocks/elements/Chasis.vue'
import Engine from '@/views/Rental/Stocks/elements/Engine.vue'
import LicencePlate from '@/views/Rental/Stocks/elements/LicencePlate.vue'
import LicenseNumber from '@/views/Rental/Stocks/elements/LicenseNumber.vue'
import ArventoNo from '@/views/Rental/Stocks/elements/ArventoNo.vue'
import UttsNo from '@/views/Rental/Stocks/elements/UttsNo.vue'
import Colors from '@/views/Rental/Stocks/elements/Colors.vue'
import Fuels from '@/views/Rental/Stocks/elements/Fuels.vue'
import Gears from '@/views/Rental/Stocks/elements/Gears.vue'
import ModelYear from '@/views/Rental/Stocks/elements/ModelYear.vue'
import StockStatus from '@/views/Rental/Stocks/elements/StockStatus.vue'
import Checkboxes from '@/views/Rental/Stocks/elements/Checkboxes.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    AracKodu,
    RegistrationDate,
    Brands,
    Models,
    CarTitle,
    Chasis,
    Engine,
    LicencePlate,
    LicenseNumber,
    ArventoNo,
    UttsNo,
    Colors,
    Fuels,
    Gears,
    ModelYear,
    StockStatus,
    Checkboxes,
  },
  props: {
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalStocks/dataItem']
    },
  },
}
</script>
