<template>
  <b-form-group
    label="UTTS No"
    label-for="utts_no"
  >
    <b-form-input
      id="utts_no"
      v-model="dataItem.utts_no"
      placeholder="UTTS No"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'UttsNo',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalStocks/dataItem']
    },
  },
}
</script>
